import moment from "moment-timezone"
import { useEffect, useState } from "react"
import * as React from "react"
import { Accordion, Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import branding from "../../branding/branding"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { DetailNavLink } from "../../contentArea/detailPages/DetailNavLink"
import { logout as loginModuleLogout } from "../../contentArea/loginregistration/LoginModule"
import { useAppState } from "../../globalStates/AppState"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { editMyProfilePageRoute, myProfilePageRoute } from "../../navigationArea/RoutePaths"
import { endSession } from "../../tracking/RouteTracker"
import { AvatarWithPresenceState } from "../../ui/AvatarWithPresenceState"
import { ButtonColored } from "../../ui/Buttons"
import {
    IconArrowDownSettings,
    IconArrowHeadDown,
    IconArrowHeadUp,
    IconArrowUpSettings,
    IconBlockContact,
    IconCamera,
    IconChevronLeft,
    IconChevronRight,
    IconEdit,
    IconHide,
    IconSettings,
    IconShow,
    IconTimeZone
} from "../../ui/Icons"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import ToggleSwitch from "../../ui/ToggleSwitch"
import { findTimezoneName, getTimezones, Timezone } from "../../utils/DateUtils"
import { TabContentArea, TabRoot } from "../CommunicationArea"
import { DoNotDisturbToggle } from "../components/DoNotDisturbToggle"
import NetworkingToggle from "../components/NetworkingToggle"
import { Divider } from "../ContactEntry"
import { BlockedTab } from "../NetworkingTab"
import ThirdPartySettingsComponent from "./ThirdPartySettingsModule"
import { BasisPremiumType, getBasisPremiumByNumber, getBasisPremiumConfig } from "../../branding/BasisPremiumBranding"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { getOrgaPersonSettings, setOrgaPersonSettings, Settings } from "../../backendServices/SeriesOfTopicsUserServices"
import { logout } from "../../backendServices/AuthenticationServices"
import { updateUserValues, PresenceType } from "../../backendServices/GraphQLServices"

const version = document.getElementById("version")?.getAttribute("data-value")

const HeaderArea = styled.div`
    display: grid;
    grid-template-columns: 20% 80%;
    flex-direction: row;
    margin-left: 15px;
    background-color: #fff;
    width: inherit;
    overflow: hidden;
    padding-bottom: 5px;
    color: ${branding.mainInfoColor ?? "black"};
`

const HeaderButtons = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    margin-top: 8px;

    & > :first-child {
        flex-grow: 1.61;
    }

    & > :last-child {
        flex-grow: 1;
        cursor: pointer;
    }

    & button {
        padding: 0;
        font-size: 0.7rem;
        line-height: 0.7rem;
        height: 25px;
        margin: 0 15px 5px 0;
    }

    & svg {
        transform: scale(0.8);
    }
`

const SettingsArea = styled.div`
    .settingsOpen & {
        visibility: visible;
    }
    svg {
        color: ${branding.primaryColor ?? "black"};
    }
`

export const SettingsAreaRow = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: grid;
    margin-left: 15px;
    grid-template-columns: 15% 65% 20%;
    padding-bottom: 10px !important;
    padding-top: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #fff;
    width: inherit;
    color: ${branding.settingsTab.settingsAreaRowColor ?? "#000"};
    & svg {
        width: 20px;
        height: 20px;
    }

    /* & path {
        fill: ${branding.sideIconBar.sideIconColorDark};
        stroke: ${branding.sideIconBar.sideIconColorDark};
    } */
`

const CurrentUserOrganization = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    display: inline-block;
    width: 250px;
    margin-left: 10px;
`

const OrganizationRoot = styled.div<{ visibility: string }>`
    visibility: ${(props) => props.visibility};
    display: flex;
    flex-flow: column;
    margin-left: 20px;
    padding-top: 0px;
    cursor: pointer;
`

const OrganizationLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    padding: 5px;
    background: #fff;
`

const CurrentUserName = styled.div`
    margin-top: 5px;
    margin-left: 2px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    .networkingCollapsed & {
        visibility: hidden;
    }
`

const CurrentUserPosition = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-size: 12px;
    margin-left: 2px;
    .networkingCollapsed & {
        visibility: hidden;
    }
`

const BottomMenuCompanyLogo = styled.div<{ showShadow: boolean }>`
    height: 100px;
    font-family: ${branding.font1};
    font-weight: 300;
    font-size: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fff;
    color: ${branding.mainInfoColor ?? "black"};
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTT : "initial")};

    & img {
        width: 26%;
        margin-bottom: 10px;
    }

    & div {
        font-size: 14px;
    }
`

const SubTabRoot = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 320px);
    overflow-x: hidden;
    color: ${branding.mainInfoColor ?? "black"};
    position: relative;
`

const CustomDivider = styled(Divider)`
    .networkingCollapsed & {
        visibility: hidden;
    }
`

interface TimezonePickerProps {
    timezoneToggle: boolean
    timezonePicker: boolean
    setTimezonePicker: (timezonePicker: boolean) => void
}

const TimezonePickerRow = styled.div<{ selected?: boolean }>`
    display: flex;
    margin: ${branding.settingsTab.timezonePickerRowMarginTop} 23px 5px 15px;
    color: ${branding.deviceSwitcher.deviceSwitcherRowColor ?? "#000"};
    cursor: pointer;
    p {
        flex: 1;
        color: ${(props) => (props.selected ? "green " : "")};
        text-overflow: ellipsis;
        overflow: hidden;
    }

    svg {
        width: 20px;
        cursor: pointer;
    }
`

const TimezoneSelectRoot = styled.div`
    height: 300px;
    overflow: auto;
`

const TimezonePicker: React.FC<TimezonePickerProps> = (props) => {
    const timezones = getTimezones()
    const appState = useAppState()
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone)
    const strings = useLanguageState().getStrings()

    function onTimezoneSelect(item: Timezone) {
        setSelectedTimezone(item.timezones[0])
        appState.setTimeZone(item.timezones[0])
    }

    useEffect(() => {
        if (props.timezoneToggle) setSelectedTimezone(moment.tz.guess())
    }, [props.timezoneToggle])

    return (
        <>
            <TimezonePickerRow
                onClick={() => props.setTimezonePicker(!props.timezonePicker)}
                style={{
                    opacity: props.timezoneToggle ? "0.25" : "",
                    pointerEvents: props.timezoneToggle ? "none" : "auto",
                    borderBottom: props.timezonePicker ? "1px solid" : ""
                }}
            >
                <p style={{ marginBottom: "15px" }}>
                    {selectedTimezone ? findTimezoneName(selectedTimezone) : strings.communicationArea.timezonePickerText}
                </p>
                <div>
                    {props.timezonePicker
                        ? IconArrowHeadUp({ fill: branding.sideIconBar.sideIconColorDark })
                        : IconArrowHeadDown({ fill: branding.sideIconBar.sideIconColorDark })}
                </div>
                <br />
            </TimezonePickerRow>
            {props.timezonePicker && (
                <TimezoneSelectRoot>
                    {timezones.map((item) => {
                        return (
                            <TimezonePickerRow
                                selected={selectedTimezone === item.timezones[0]}
                                onClick={() => onTimezoneSelect(item)}
                            >
                                <p>{item.name}</p>
                                <div style={{ marginLeft: "25px" }}></div>
                            </TimezonePickerRow>
                        )
                    })}
                </TimezoneSelectRoot>
            )}
        </>
    )
}

interface WithBlockedContentToggle {
    setShowBlocked: (showBlocked: boolean) => void
}

interface SettingsTabProps {}

const SettingsTab: React.FunctionComponent<SettingsTabProps> = (props) => {
    const userState = useLoggedInState()

    const [showBlocked, setShowBlocked] = useState(false)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    useEffect(() => {
        if (!isLoaded) {
            setIsLoaded(true)
        }
        // eslint-disable-next-line
    }, [userState, isLoaded])

    const content = showBlocked ? (
        <BlockedContacts setShowBlocked={setShowBlocked} />
    ) : (
        <SettingsContent setShowBlocked={setShowBlocked} />
    )

    return (
        <TabRoot>
            <TabContentArea className="rs-nav-content">
                <SettingsArea>{content}</SettingsArea>
            </TabContentArea>
        </TabRoot>
    )
}

export default SettingsTab

const StyledAccordion = styled(Accordion)<{ open: boolean }>`
    position: relative;
    font-family: ${branding.font1};

    & > button {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid ${branding.crsTabs.tabItemDefaultTextColor};
        border-radius: 0px;
        margin: 0 30px 0 0;
        color: ${(props) =>
            props.open ? branding.crsTabs.tabItemDefaultActiveStateColor : branding.crsTabs.tabItemDefaultTextColor} !important;
        text-decoration: none !important;
        padding: 0 0 0 20px;
        height: 55px;

        &:focus {
            box-shadow: none;
        }
    }

    & > div {
        border-bottom: 1px solid ${branding.crsTabs.tabItemDefaultTextColor};
    }

    &:after {
        display: none;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: ${branding.font1};
`

const ButtonIconContainer = styled.div`
    margin-left: 10px;
    width: 20px;
    height: 20px;
`

const CompanyLogo = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`

interface SettingsAccordionProps {
    title: string
    defaultActiveKey?: string
}
export const SettingsAccordion: React.FunctionComponent<SettingsAccordionProps> = (props) => {
    const [currentEventKey, setCurrentEventKey] = useState<string | undefined>()
    return (
        <StyledAccordion
            key={props.title}
            defaultActiveKey={props.defaultActiveKey}
            open={currentEventKey === "0"}
            activeKey={currentEventKey}
        >
            <Button variant="link" onClick={() => setCurrentEventKey(currentEventKey === "0" ? undefined : "0")}>
                <ButtonContainer>
                    <div>{props.title}</div>
                    <ButtonIconContainer>
                        {currentEventKey === "0"
                            ? IconArrowUpSettings({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })
                            : IconArrowDownSettings({ fill: branding.crsTabs.tabItemDefaultTextColor })}
                    </ButtonIconContainer>
                </ButtonContainer>
            </Button>
            <Accordion.Collapse eventKey="0">
                <>{props.children}</>
            </Accordion.Collapse>
        </StyledAccordion>
    )
}

interface OrgaAccordionProps {
    organization: any
}

export const OrgaAccordion: React.FunctionComponent<OrgaAccordionProps> = (props) => {
    const userState = useLoggedInState()
    const profileId = userState.user()?.profileId
    const strings = useLanguageState().getStrings()

    const [settings, setSettings] = useState<Settings>()
    const [showSettings, setShowSettings] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            if (profileId === undefined) {
                return
            }

            const response = await getOrgaPersonSettings(profileId as string, props.organization.id as string)

            if ((response as BackendServiceError).httpStatus) {
            } else {
                setSettings(response as Settings)
            }
        })()
        // eslint-disable-next-line
    }, [])

    function setSettingVisible() {
        ;(async () => {
            if (profileId === undefined) {
                return
            }

            const response = await setOrgaPersonSettings(profileId as string, props.organization.id as string, {
                visibleAsContact: !settings?.visibleAsContact,
                showroomStandby: settings?.showroomStandby
            })

            if ((response as BackendServiceError).httpStatus) {
            } else {
                setSettings(response as Settings)
            }
        })()
    }

    function checkIfShowroomEnabled(): boolean {
        let basisPremiumType: BasisPremiumType = getBasisPremiumByNumber(props.organization.basisPremium ?? -1)
        let config = getBasisPremiumConfig(basisPremiumType)

        return config.expoShowroomEnabled
    }

    function setSettingShowroomStandby() {
        ;(async () => {
            if (profileId === undefined) {
                return
            }

            const response = await setOrgaPersonSettings(profileId as string, props.organization.id as string, {
                visibleAsContact: settings?.visibleAsContact,
                showroomStandby: !settings?.showroomStandby
            })

            if ((response as BackendServiceError).httpStatus) {
            } else {
                setSettings(response as Settings)
            }
        })()
    }

    return (
        <>
            <DetailNavLink
                id={props.organization?.id!}
                type="organization"
                name={props.organization?.name ?? ""}
                source="PROFILE"
                width={"100%"}
            >
                <div className="d-flex align-items-center" style={{ marginTop: "20px" }}>
                    <OrganizationLogoDiv>
                        {props.organization.logo ? (
                            <CompanyLogo src={props.organization?.logo!} alt="" />
                        ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                            <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                        ) : (
                            <span style={{ fontFamily: branding.font1, fontSize: "16px" }}>
                                {props.organization.name ? props.organization.name.charAt(0) : ""}
                            </span>
                        )}
                    </OrganizationLogoDiv>
                    <CurrentUserOrganization>{props.organization.name}</CurrentUserOrganization>
                    {branding.communicationArea.showShowAsContactAndStandBy && (
                        <div
                            style={{ marginRight: "23px" }}
                            onClick={(e: any) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setShowSettings(!showSettings)
                            }}
                        >
                            {showSettings
                                ? IconArrowHeadUp({ fill: branding.sideIconBar.sideIconColorDark })
                                : IconArrowHeadDown({ fill: branding.sideIconBar.sideIconColorDark })}
                        </div>
                    )}
                </div>
            </DetailNavLink>
            <>
                {branding.communicationArea.showShowAsContactAndStandBy && settings && showSettings && (
                    <div style={{ width: "100%" }}>
                        <SettingsAreaRow style={{ width: "100%", marginLeft: "5px" }}>
                            {settings.visibleAsContact
                                ? IconShow({ fill: branding.sideIconBar.sideIconColorDark })
                                : IconHide({ fill: branding.sideIconBar.sideIconColorDark })}
                            <p>{strings.communicationArea.showAsContactTitle}</p>
                            <ToggleSwitch isEnabled={settings.visibleAsContact} onClick={() => setSettingVisible()} />
                        </SettingsAreaRow>

                        {checkIfShowroomEnabled() && (
                            <SettingsAreaRow style={{ width: "100%", marginLeft: "5px" }}>
                                {IconCamera({ fill: branding.sideIconBar.sideIconColorDark })}
                                <p>{strings.communicationArea.standByForShowroom}</p>
                                <ToggleSwitch isEnabled={settings.showroomStandby} onClick={() => setSettingShowroomStandby()} />
                            </SettingsAreaRow>
                        )}
                    </div>
                )}
            </>
        </>
    )
}

interface SettingsContentProps extends WithBlockedContentToggle {}

export const logoutUser = async (userState: any, history: any, appState: any, favoriteState: any, chime: any) => {
    localStorage.setItem("logoutInProcess", "true")

    const profileId = userState.user()?.profileId
    if (profileId) {
        await updateUserValues({ id: profileId, presenceStatus: PresenceType.OFFWORK, lastConnected: new Date().toISOString() })
        await endSession()
        await logout(profileId)
        await loginModuleLogout()
    }
    history.push("/")
    userState.setUserState({})
    localStorage.removeItem("routeBeforeCall")
    appState.reset()
    favoriteState.reset()
    const chimeName = chime.getName()
    if (chimeName && chimeName.length > 0) {
        chime.leaveRoom()
    }

    const element = document.getElementById("hubspot-messages-iframe-container")
    if (element) element.style.cssText += ";right: 0px !important;"
}

function SettingsContent(props: SettingsContentProps) {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const history = useHistory()
    const appState = useAppState()
    const chime = useChimeContext()
    const favoriteState = useFavoriteState()

    const localizedSalutation =
        lang === "de" ? userState.user()?.salutationDe ?? userState.user()?.salutation : userState.user()?.salutation
    const currentUserName: string = branding.myProfilePageContent.salutationVisible
        ? [localizedSalutation, userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
        : [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
    const currentUserNameNoSalutation = userState.user()?.firstName + " " + userState.user()?.lastName
    const currentUserPosition: string = [
        lang === "en" ? userState.user()?.position : userState.user()?.positionDe ?? userState.user()?.position,
        userState.user()?.company
    ]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")
    const corussoftLogo = "/corus_RGB_L_dark.png"

    const [contentAreaScrollable, setContentAreaScrollable] = useState(false)
    const [hideOnScrollEnd, setHideOnScrollEnd] = useState(false)

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScrollEnd(scrollValues.contentScrollHeight === scrollValues.clientHeight + scrollValues.scrollTop)
        } else {
            setHideOnScrollEnd(true)
        }
    }

    const onAvatarClick = () => {
        history.push(myProfilePageRoute)
    }

    const onEditProfileClick = () => {
        history.push(editMyProfilePageRoute)
    }

    return (
        <>
            <HeaderArea>
                <div style={{ cursor: "pointer" }} onClick={() => onAvatarClick()}>
                    <AvatarWithPresenceState
                        avatarSize={56}
                        badgeSize={15}
                        showAvatarBadge={true}
                        badgeRight={0.1}
                        badgeTop={37}
                        userId={userState.user()?.profileId || ""}
                        initPresenceByList={false}
                        userType={userState.user()?.type}
                        content={{ pictureUrl: userState.user()?.logoUrl, alt: currentUserNameNoSalutation }}
                    ></AvatarWithPresenceState>
                </div>
                <div>
                    <div style={{ cursor: "pointer" }} onClick={() => onAvatarClick()}>
                        <CurrentUserName style={{ fontSize: 16 }}>
                            {currentUserName}
                            <br />
                        </CurrentUserName>
                        <CurrentUserPosition style={{ fontSize: 12 }}>{currentUserPosition}</CurrentUserPosition>
                    </div>
                    <HeaderButtons>
                        <ButtonColored
                            color={branding.communicationArea.editProfileButtonColor}
                            onClick={() => onEditProfileClick()}
                        >
                            <IconEdit fill={branding.communicationArea.editProfileButtonColor} />
                            {strings.myProfilePageContent.editProfileButtonText}
                        </ButtonColored>
                        <ButtonColored
                            color={branding.dangerButtonColor}
                            onClick={() => logoutUser(userState, history, appState, favoriteState, chime)}
                        >
                            {strings.communicationArea.logOutTitle}
                        </ButtonColored>
                    </HeaderButtons>
                </div>
            </HeaderArea>
            <CustomDivider style={{ marginLeft: "0px" }} />
            <SubTabRoot>
                <ContentScrollContainer handleScroll={onScroll} isVerticallyScrollable={setContentAreaScrollable} padding="0">
                    <ThirdPartySettingsComponent />
                    <GeneralSettings setShowBlocked={props.setShowBlocked} />
                    <MyOrganization />
                </ContentScrollContainer>
            </SubTabRoot>
            <div style={{ position: "relative", zIndex: 10 }}>
                <BottomMenuCompanyLogo showShadow={contentAreaScrollable && !hideOnScrollEnd}>
                    {branding.communicationArea.corussoftLogoVisibleInCC && <img src={corussoftLogo} alt="Corussoft GmbH" />}
                    <div>Version {version}</div>
                </BottomMenuCompanyLogo>
            </div>
        </>
    )
}

interface BlockedContactsProps extends WithBlockedContentToggle {}
function BlockedContacts(props: BlockedContactsProps) {
    const strings = useLanguageState().getStrings()
    return (
        <>
            <SettingsAreaRow style={{ cursor: "pointer" }} onClick={() => props.setShowBlocked(false)}>
                <div>{IconChevronLeft({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                <p>{strings.communicationArea.blockedContactsTitle}</p>
            </SettingsAreaRow>
            <BlockedTab />
        </>
    )
}

interface GeneralSettingsProps extends WithBlockedContentToggle {}
function GeneralSettings(props: GeneralSettingsProps) {
    const strings = useLanguageState().getStrings()
    const appState = useAppState()

    const [timezoneToggle, setTimezoneToggle] = useState<boolean>(appState.detectTimezoneToggle)
    const [timezonePicker, setTimezonePicker] = useState<boolean>(false)

    useEffect(() => {
        if (timezoneToggle === false) {
            setTimezonePicker(false)
        } else {
            appState.setTimeZone(moment.tz.guess())
            setTimezonePicker(false)
        }
    }, [timezoneToggle]) //eslint-disable-line

    return (
        <SettingsAccordion title={strings.communicationArea.generalSettings}>
            <SettingsAreaRow>
                <AudioVideoSettingsToggle
                    isAVSettingsOpen={appState.isAudioVideoSettingsOpen}
                    onClick={() => {
                        appState.setIsAudioVideoSettingsOpen(true)
                    }}
                >
                    {IconSettings({ fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px" })}
                    <AudioVideoSettingsLabel>{branding.communicationAreaAudioVideoSettingsLabel}</AudioVideoSettingsLabel>
                </AudioVideoSettingsToggle>
            </SettingsAreaRow>
            <SettingsAreaRow style={{ cursor: "pointer" }} onClick={() => props.setShowBlocked(true)}>
                {IconBlockContact({ fill: branding.sideIconBar.sideIconColorDark })}
                <p>{strings.communicationArea.blockedContactsTitle}</p>
                <div style={{ marginLeft: "25px" }}>
                    {IconChevronRight({ fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px" })}
                </div>
            </SettingsAreaRow>
            <NetworkingToggle />
            <DoNotDisturbToggle />

            <SettingsAreaRow>
                {IconTimeZone({ fill: branding.sideIconBar.sideIconColorDark })}
                <p>{strings.communicationArea.timezone}</p>
                <ToggleSwitch
                    isEnabled={appState.detectTimezoneToggle}
                    onClick={() => {
                        setTimezoneToggle(!timezoneToggle)
                        appState.setDetectTimezoneToggle(!timezoneToggle)
                    }}
                />
            </SettingsAreaRow>

            <TimezonePicker
                setTimezonePicker={setTimezonePicker}
                timezoneToggle={timezoneToggle}
                timezonePicker={timezonePicker}
            ></TimezonePicker>
        </SettingsAccordion>
    )
}

function MyOrganization() {
    const strings = useLanguageState().getStrings()
    const userState = useLoggedInState()
    const isLogoutInProcess = localStorage.getItem("logoutInProcess")

    if (!userState.user()?.organizations || userState.user()?.organizations?.length! === 0) return null

    return (
        <SettingsAccordion title={strings.communicationArea.myOrganizationTitle}>
            <div>
                <OrganizationRoot visibility={"visible"} className="d-flex align-items-center justify-content-center">
                    {!isLogoutInProcess &&
                        userState.user()?.organizations?.map((organization: any, index) => {
                            return <OrgaAccordion key={index} organization={organization} />
                        })}
                </OrganizationRoot>
                <br />
            </div>
        </SettingsAccordion>
    )
}

export const SettingsButtonStyled = styled(Button)`
    background: none !important;
    color: inherit !important;
    border: none !important;
    text-align: left !important;
    padding: 0 !important;
    margin: 0;
    &:focus {
        box-shadow: none !important;
    }
`

const AudioVideoSettingsToggle = styled.div<{ isAVSettingsOpen: boolean }>`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    cursor: pointer;
    color: ${(props) =>
        props.isAVSettingsOpen
            ? branding.communicationAreaAudioVideoSettingsActiveColor
            : branding.settingsTab.settingsAreaRowColor};

    svg {
        path {
            fill: ${(props) =>
                props.isAVSettingsOpen
                    ? branding.communicationAreaAudioVideoSettingsActiveColor
                    : branding.sideIconBar.sideIconColorDark};
        }
    }

    :hover {
        color: ${branding.communicationAreaAudioVideoSettingsActiveColor};
        svg {
            path {
                fill: ${branding.communicationAreaAudioVideoSettingsActiveColor};
            }
        }
    }
`

const AudioVideoSettingsLabel = styled.p`
    margin-left: 25px;
    display: block;
    white-space: nowrap;
`
